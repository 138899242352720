<template>
  <v-main tag="main" :class="{ darkb: $vuetify.theme.dark, whiteb: !$vuetify.theme.dark }">
    <v-container id="main-container" class="overflow-y-auto" fluid>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-container>
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    // DashboardCoreFooter: () => import("./Footer")
  }
}
</script>
<style>
#main-container {
  height: calc(100vh - 50px);
}
.darkb {
  background-color: #1e1e1e !important;
}
.whiteb {
  background-color: #eeeeee !important;
}
</style>
